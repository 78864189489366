.projects{
  &-slider{
    height: 565px;
    margin-top: 100px;
  }
  &-i{
    display: flex;
    height: 100%;
  }
  &-img{
    max-width: 40%;
    min-height: 565px;
    width: 100%;
    background-size: cover;
    background-color: $c-primary-dark;
  }
  &-cnt{
    padding: 50px;
    color: $c-txt;
    &-ttl{
      font-size: 46px;
      font-weight: 600;
      line-height: 1.2;
    }
    &-lst{
      display: flex;
      align-items: baseline;
      padding: 30px 0;
    }
    &-i{
      margin-right: 5%;
    }
    &-name{
      font-weight: 500;
      font-size: 16px;
      color: $c-desc;
      padding-bottom: 10px;
    }
    &-val{
      font-weight: 600;
      font-size: 21px;
    }
    &-txt{
      font-weight: 500;
      font-size: 18px;
      line-height: 1.5;
      max-height: 190px;
      overflow: hidden;
    }
    &-btn{
      display: block;
      position: relative;
      max-width: 345px;
      width: 100%;
      padding: 30px 50px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: $c-base;
      margin-top: 25px;
      background: $c-primary;
      border-radius: 66px;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 30px auto;
        width: 11px;
        height: 11px;
        @extend .s-btn-arr;
      }
      &:hover{
        background: $c-primary-light;
      }
    }
  }
  &-blk{
    display: flex;
    align-items: flex-start;
    max-width: 60%;
    background: $c-base;
  }
  &-gallery{
    &-img{
      width: 220px;
      height: 175px;
      background-size: cover;
      margin: 10px;
    }
  }
  &-nav{
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    &-btn{
      position: relative;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid $c-base;
      transition: all .2s;
      width: 80px;
      height: 80px;
      line-height: 80px;
      cursor: pointer;
      &:after{
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 34px;
        height: 16px;
        @extend .s-arr-white;
      }
      &:first-of-type{
        margin-right: 10px;
      }
      &:last-of-type{
        &:after{
          transform: rotate(180deg);
        }
      }
      &:hover{
        background: $c-primary;
        border-color: $c-primary;
      }
    }
  }
  .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 60px;
    margin-right: 180px;
  }
  .owl-theme .owl-dots {
    display: flex;
    align-items: center;
    text-align: left;
  }
  .owl-theme .owl-dots .owl-dot span{
    position: relative;
    width: 6px;
    height: 6px;
    margin: 5px 15px;
    background: $c-desc;
    opacity: .3;
  }
  .owl-theme .owl-dots .owl-dot{
    &:first-of-type{
      span{
        margin-left: 0;
      }
    }
  }
  .owl-theme .owl-dots .owl-dot:hover span{
    background: $c-base;
    opacity: 1;
  }
  .owl-theme .owl-dots .owl-dot.active span{
    width: 36px;
    height: 36px;
    background: $c-primary;
    opacity: 1;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 6px;
      height: 6px;
      background: $c-base;
      border-radius: 50%;
    }
  }
}
@media screen and (max-width: 1320px){
  .projects{
    &-slider {
      height: 520px;
      width: 100%;
    }
    &-img{
      max-width: 30%;
      min-height: 520px;
    }
    &-cnt{
      padding: 30px;
      &-btn{
        max-width: 100%;
      }
    }
    &-blk{
      max-width: 70%;
    }
    &-gallery{
      &-img{
        width: 200px;
        height: 160px;
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .projects{
    &-cnt{
      &-btn{
        padding: 30px 50px 30px 20px;
      }
    }
  }
}
@media screen and (max-width: 1024px){
  .projects{
    &-gallery{
      display: none;
    }
    &-slider{
      height: 500px;
    }
    &-img{
      max-width: 40%;
      min-height: 500px;
    }
    &-blk{
      max-width: 60%;
    }
    &-cnt{
      &-ttl{
        font-size: 34px;
      }
    }
  }
}
@media screen and (max-width: 800px){
  .projects{
    &-gallery{
      display: none;
    }
    &-slider{
      margin-top: 50px;
      height: 760px;
    }
    &-i{
      flex-wrap: wrap;
    }
    &-img{
      max-width: 100%;
      min-height: 400px;
      background-position: center center;
    }
    &-blk{
      max-width: 100%;
    }
    &-cnt{
      &-ttl{
        font-size: 23px;
      }
      &-name{
        font-size: 14px;
      }
      &-txt{
        font-size: 14px;
      }
    }
    &-nav{
      display: none;
    }

    .owl-theme .owl-nav.disabled+.owl-dots {
      margin-top: 60px;
      margin-right: 0;
    }
    .owl-theme .owl-dots {
      justify-content: center;
    }
  }
}
@media screen and (max-width: 680px){
  .projects{
    &-slider{
      height: 610px;
    }
    &-img {
      min-height: 230px;
    }
    &-cnt{
      padding: 25px;
      &-btn{
        padding: 25px 45px 25px 15px;
        font-size: 13px;
      }
    }
  }
}