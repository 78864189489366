.main{
  background: $c-primary-dark;
  &-i{
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 1250px;
  }
  &-blk{
    display: flex;
    justify-content: space-between;
  }
  &-cnt{
    position: relative;
    padding: 225px 0 100px;
    max-width: 940px;
    &-txt{
      font-family: $ttl-font;
      font-size: 62px;
      line-height: 1.4;
    }
    &-ttl{
      display: inline-block;
      color: $c-primary-light;
    }
  }
  &-btn{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto 100px;
    width: 303px;
    height: 303px;
    background-image: url("../img/round.png");
    background-repeat: no-repeat;
    background-size: cover;
    &-mob{
      display: none;
      margin: 35px 0;
    }
    &-arr{
      display: block;
      position: relative;
      width: 130px;
      height: 130px;
      margin: 0 auto;
      border: 1px solid $c-primary-light;
      border-radius: 50%;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 27px;
        height: 27px;
        background: url("../img/object-arr-s.png") no-repeat;
        z-index: 1;
        transform: rotate(90deg);
      }
    }
    &-cnt{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      z-index: 0;
    }
    &-txt{
      font-weight: 600;
      font-size: 22px;
      line-height: 1.4;
      padding-top: 115px;
    }
    &-lnk{
      display: block;
      position: absolute;
      right: -11px;
      bottom: 15px;
      width: 92px;
      height: 92px;
      border-radius: 50%;
      border: 1px solid transparent;
      &:after{
        content: '';
        display: block;
        position: absolute;
        right: 33px;
        bottom: 33px;
        width: 25px;
        height: 25px;
        z-index: 1;
        @extend .s-main-arr;
      }
      &:hover{
        background: $c-base;
        border-color: $c-primary-light;
      }
    }
  }
  &-r{
    position: relative;
    align-self: flex-end;
    margin-left: 30px;
    background: $c-primary-light;
    padding: 80px 0 100px 80px;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 98%;
      width: 1000px;
      background: $c-primary-light;
    }
  }
  &-slider{
    text-transform: uppercase;
    padding-bottom: 170px;
    &-ttl{
      font-weight: 600;
      font-size: 18px;
      padding-bottom: 40px;
      opacity: 0.33;
    }
    &-txt{
      font-weight: 500;
      font-size: 21px;
      line-height: 1.5;
    }
    &-nav{
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 80px;
      &-btn{
        position: relative;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid $c-base;
        transition: all .2s;
        width: 80px;
        height: 80px;
        line-height: 80px;
        cursor: pointer;
        &:after{
          content: '';
          position: absolute;
          display: block;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 34px;
          height: 16px;
          @extend .s-arr-white;
        }
        &:first-of-type{
          margin-right: 10px;
        }
        &:last-of-type{
          &:after{
            transform: rotate(180deg);
          }
        }
        &:hover{
          background: $c-base;
          &:after{
            @extend .s-arr-primary;
          }
          &:first-of-type{
            &:after{
              transform: rotate(180deg);
            }
          }
          &:last-of-type{
            &:after{
              transform: none;
            }
          }
        }
      }
    }
    &-count{
      position: absolute;
      bottom: 0;
      right: 0;
      padding-bottom: 80px;
      font-weight: 500;
      font-size: 18px;
      opacity: 0.33;
      z-index: 1;
    }
  }
}
@media screen and (max-width: 1400px){
  .main{
    &-cnt{
      &-txt{
        font-size: 50px;
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .main{
    &-cnt{
      &-txt{
        font-size: 40px;
      }
    }
  }
}
@media screen and (max-width: 1024px){
  .main{
    &-i{
      height: 1600px;
    }
    &-blk{
      flex-wrap: wrap;
    }
    &-cnt{
      max-width: 100%;
      padding: 310px 0 50px;
    }
    &-btn{
      position: relative;
      margin: 100px auto 0;
    }
  }
}
@media screen and (max-width: 800px) {
  .main{
    &-i{
      height: 870px;
      background-size: auto 85%;
    }
    &-cnt{
      padding: 150px 0 0;
      &-txt{
        font-size: 23px;
      }
    }
    &-r{
      padding: 25px 0 25px 20px;
    }
    &-slider{
      padding-bottom: 130px;
      &-ttl{
        font-size: 12px;
        padding-bottom: 10px;
      }
      &-txt{
        font-size: 12px;
      }
      &-nav{
        padding: 25px;
        &-btn{
          width: 60px;
          height: 60px;
          line-height: 60px;
        }
      }
      &-count{
        padding-bottom: 25px;
        font-size: 10px;
      }
    }
    &-btn{
      display: none;
      &-mob{
         display: block;
      }
    }
  }
}