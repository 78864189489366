//Bootstarp grid
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import '../../../node_modules/bootstrap/scss/_mixins.scss';
@import '../../../node_modules/bootstrap/scss/_grid.scss';

*{
  box-sizing: border-box;
}
html,
body{
  height: 100%;
  line-height: 1;
  color: $c-base;
  font-size: 18px;
  font-family: $base-font;
  font-weight: 500;
}

.container{
  max-width: $width-container + ($padding-container * 2);
  @include make-container(); /* align center, add default padding*/
  padding-left: $padding-container;
  padding-right: $padding-container;
}

.col{
  @include make-col-ready();
  @include make-col(4);

}
.row{
  @include make-row();
}

.section{
  &.__dark{
    background: $c-primary-dark;
  }
}
.section-wrap{
  position: relative;
  padding: 140px 0 75px;
  &.__why{
    background: $c-primary-dark;
    &:after{
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: -50px;
      background: url("../img/object.png") no-repeat;
      background-size: cover;
      max-width: 500px;
      width: 100%;
      height: 600px;
      z-index: 1;
    }
  }
  &.__advantage,
  &.__projects,
  &.__questions{
    background: $c-primary-dark;
  }
  &.__advantage{
    padding-bottom: 0;
  }
  &.__projects{
    padding: 140px 0 0;
  }
  &.__questions{
    padding-bottom: 140px;
  }
  &.__contacts{
    background: url("../img/map.png") center center no-repeat;
    background-size: cover;
  }
  &.__consultation{
    position: relative;
    background: url("../img/consultation.png") no-repeat;
    background-size: cover;
    padding-bottom: 170px;
    &:before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url("../img/rectangle.png") no-repeat;
      background-size: cover;
    }
  }
  &.__footer{
    padding-top: 100px;
    padding-bottom: 45px;
  }
  &.__mob{
    display: none;
    height: 300px;
    background: $c-primary-dark;
  }
}
.h-100{
  height: 100%;
}

/* footer to bottom of page */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}
.cnt-wrap {
  position: relative;
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}
/* end footer to bottom of page */

@media screen and (max-width: 1400px){
  .container{
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 1024px){
  .section-wrap{
    &.__why{
      &:after{
        max-width: 300px;
        height: 300px;
        margin-bottom: 150px;
      }
    }
  }
}

@media screen and (max-width: 800px){
  .__pl {
    padding-left: 0;
  }
  .section-wrap {
    position: relative;
    padding: 60px 0 60px;
    &.__consultation{
      padding-bottom: 60px;
    }
    &.__questions{
      padding-top: 170px;
      padding-bottom: 80px;
    }
    &.__projects,
    &.__advantage {
      padding-top: 75px;
    }
    &.__about{
      padding-top: 80px;
    }
  }
}
@media screen and (max-width: 460px){
  .section-wrap {
    &.__questions{
      padding-top: 200px;
    }
    &.__why{
      &:after{
        max-width: 135px;
        height: 190px;
        margin-bottom: 210px;
      }
    }
  }
}
@media screen and (max-width: 360px){
  .section-wrap {
    &.__questions{
      padding-top: 230px;
    }
  }
}