input, textarea{
  display: block;
  width: 100%;
  border: 2px solid $c-base;
  color: #9CACC3;
  padding: 50px 60px 50px 35px;
  font-family: $base-font;
  font-weight: 500;
  font-size: 20px;
  border-radius: 0;
  outline: none;
  &:focus{
    outline: none;
    //border-color: $c-primary;
  }
  &::-webkit-input-placeholder {
    font-family: $base-font;
    font-weight: 500;
    font-size: 20px;
  }
  &::-moz-placeholder{
    font-family: $base-font;
    font-weight: 500;
    font-size: 20px;
  }/* Firefox 19+ */
  &:-moz-placeholder{
    font-family: $base-font;
    font-weight: 500;
    font-size: 20px;
  }/* Firefox 18- */
  &:-ms-input-placeholder{
    font-family: $base-font;
    font-weight: 500;
    font-size: 20px;
  }

}

input{
  height: 45px;
  line-height: 45px;
}
input[type='submit'],
button[type='submit']{
  outline: none;
  cursor: pointer;
  //@extend .btn;
}

textarea{
  resize: vertical;
  padding: 10px;
}
.wrap-inp{
  margin-bottom: 20px;
}
#form-status{
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
#form-status.with_error{
  color: #ff0000;
}
.error{
  border:2px solid #ff0000 !important;
}

/* checkbox */
.checkbox{
  display: none;
  &:checked+.checkbox-lbl{
    &:after{
    }
  }
  &-lbl{
    cursor: pointer;
    display: block;
    text-align: left;
    padding-left: 16px;
    position: relative;
    &:after{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      width: 6px;
      height: 6px;
      background: #d1d1d1;
      transition: background .2s;
    }
    &:hover{

    }
  }
}
@media screen and (max-width: 800px) {
  input, textarea{
    &::-webkit-input-placeholder {
      font-size: 14px;
    }
    &::-moz-placeholder{
      font-size: 14px;
    }/* Firefox 19+ */
    &:-moz-placeholder{
      font-size: 14px;
    }/* Firefox 18- */
    &:-ms-input-placeholder{
      font-size: 14px;
    }
  }
}