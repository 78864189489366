.about{
  padding-bottom: 50px;
  &-wrap{
    display: flex;
    justify-content: space-between;
  }
  &-l{
    max-width: 470px;
    padding-right: 45px;
    flex-shrink: 0;
  }
  &-r{
    max-width: 900px;
    overflow: hidden;
    //width: 100%;
  }
  &-lst{
    counter-reset: li;
    margin: 60px 0 0;
    padding-left: 0;
  }
  &-i{
    position: relative;
    margin: 0 0 40px 0;
    padding: 4px 0 4px 60px;
    list-style: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.8;
    color: $c-txt;
    &:before {
      content: '0'counter(li);
      counter-increment: li;
      position: absolute;
      top: 12px;
      left: 0;
      width: 36px;
      height: 36px;
      font-size: 14px;
      font-weight: 500;
      line-height: 36px;
      color: $c-base;
      background: $c-primary;
      text-align: center;
      border-radius: 50%;
    }
  }
  &-gallery{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-l{
      padding-right: 10px;
      max-width: 190px;
      width: 100%;
    }
    &-row{
      @extend .row;
      margin-right: -5px;
      margin-left: -5px;
      &.__col{
        flex-direction: column;
        flex-grow: 1;
      }
    }
    &-col{
      @include make-col-ready();
      @include make-col(12);
      padding: 5px;
    }
    &-card{
      @include make-col-ready();
      @include make-col(6);
      padding: 5px;
    }
    &-btn{
      display: block;
      position: relative;
      background: $c-primary;
      width: 100%;
      height: 100%;
      &:after{
        content: '';
        display: block;
        position: absolute;
        width: 32px;
        height: 24px;
        margin: 20%;
        @extend .s-gallery-arr;
      }
      &:hover{
        background: $c-primary-light;
      }
      &.__prev{
        &:after{
          top: 0;
          right: 0;
        }
      }
      &.__next{
        &:after{
          bottom: 0;
          left: 0;
          transform: rotate(180deg);
        }
      }
    }
  }
  .owl-carousel.owl-drag .owl-item{
    overflow: hidden;
  }
  .owl-carousel.owl-drag .owl-item:not(.active){
    visibility: hidden;
  }
}
@media screen and (max-width: 1200px){
  .about{
    &-l{
      max-width: 325px;
    }
    &-i{
      margin: 0 0 20px 0;
    }
  }
}
@media screen and (max-width: 1024px){
  .about{
    &-wrap{
      flex-wrap: wrap;
    }
    &-l{
      max-width: 100%;
      width: 100%;
      padding-right: 0;
      margin-bottom: 30px;
    }
    &-r{
      max-width: 100%;
      width: 100%;
    }
  }
}
@media screen and (max-width: 800px){
  .about{
    padding-bottom: 0;
    &-lst{
      margin: 30px 0 0;
    }
    &-i{
      margin: 0 0 20px 0;
    }
  }
}
@media screen and (max-width: 680px){
  .about{
    &-i{
      padding: 4px 0 4px 50px;
      font-size: 14px;
      line-height: 1.4;
      &:before{
        top: 4px;
      }
    }
    &-gallery{
      &-l{
        padding-right: 4px;
      }
      &-row{
        margin-right: -2px;
        margin-left: -2px;
      }
      &-col,
      &-card{
        padding: 2px;
      }
    }
  }
}