.contacts{
  height: 800px;
  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 15px;
    margin: 280px 0 0 56%;
    border: 10px solid $c-primary;
    border-radius: 50%;
  }
  .ttl-transform{
    &-ltr,
    &-nbr,
    &-txt{
      color: $c-contacts;
      opacity: .8;
    }
    &-txt{
      opacity: 0.2;
    }
  }
  &-t{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    //margin: 0 12%;
    margin-top: -192px;
    width: 100%;
    z-index: 1;
  }
  &-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .inp-order-lbl{
    position: relative;
    display: block;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 30px;
      bottom: 0;
      margin: auto 0;
      width: 19px;
      height: 19px;
      @extend .s-phone-call;
    }
  }
  .wrap-inp{
    max-width: 24%;
    width: 100%;
    //flex-grow: 1;
    box-shadow: 0 33px 233px rgba(0, 0, 0, .1);
    &:first-of-type{
      .contacts-inp{
        border-right: 1px solid #E8EBEF;
      }
      .inp-order-lbl{
        &:after{
          width: 21px;
          height: 21px;
          @extend .s-user;
        }
      }
    }
  }
  .wrap-sub{
    flex-shrink: 0;
    width: 400px;
  }
  &-btn{
    display: block;
    position: relative;
    width: 100%;
    padding: 40px 20px;
    font-family: $base-font;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: $c-base;
    background: $c-primary;
    outline: none;
    border: 0;
    text-transform: uppercase;
    box-shadow: 0 33px 233px rgba(0, 0, 0, .1);
    margin: 0;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto 30px auto;
      width: 11px;
      height: 11px;
      @extend .s-btn-arr;
    }
    &:hover{
      background: $c-primary-light;
    }
  }
}
@media screen and (max-width: 1200px) {
  .contacts {
    &-form{
      flex-wrap: wrap;
    }
    .wrap-inp{
      max-width: 29.5%;
    }
    &-t{
      margin: 0;
      margin-top: -192px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .contacts {
    &-form {
    }
    .wrap-inp {
      max-width: 100%;
    }
    .wrap-sub{
      width: 100%;
    }
  }
}
@media screen and (max-width: 800px) {
  .contacts {
    height: 400px;
    &:after{
      display: none;
    }
    &-t{
      margin: 0;
      margin-top: -400px;
    }
    &-form{
      .wrap-inp,
      .wrap-sub{
        width: 100%;
      }
      .contacts-inp,
      .contacts-btn{
        font-size: 14px;
        border-radius: 1000px;
      }
      .contacts-btn{
        font-size: 12px;
        padding: 30px 40px 30px 10px;
      }
      .contacts-inp{
        padding: 35px 60px 35px 35px;
      }
    }
  }
  .section-wrap.__contacts {
    background-size: auto;
  }
  .section-wrap.__mob{
    display: block;
  }
}
