// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$s-arr-primary-name: 's-arr-primary';
$s-arr-primary-x: 66px;
$s-arr-primary-y: 100px;
$s-arr-primary-offset-x: -66px;
$s-arr-primary-offset-y: -100px;
$s-arr-primary-width: 34px;
$s-arr-primary-height: 16px;
$s-arr-primary-total-width: 150px;
$s-arr-primary-total-height: 149px;
$s-arr-primary-image: '../img/sprites/sprite.png';
$s-arr-primary: (66px, 100px, -66px, -100px, 34px, 16px, 150px, 149px, '../img/sprites/sprite.png', 's-arr-primary', );
$s-arr-white-name: 's-arr-white';
$s-arr-white-x: 32px;
$s-arr-white-y: 100px;
$s-arr-white-offset-x: -32px;
$s-arr-white-offset-y: -100px;
$s-arr-white-width: 34px;
$s-arr-white-height: 16px;
$s-arr-white-total-width: 150px;
$s-arr-white-total-height: 149px;
$s-arr-white-image: '../img/sprites/sprite.png';
$s-arr-white: (32px, 100px, -32px, -100px, 34px, 16px, 150px, 149px, '../img/sprites/sprite.png', 's-arr-white', );
$s-btn-arr-name: 's-btn-arr';
$s-btn-arr-x: 106px;
$s-btn-arr-y: 124px;
$s-btn-arr-offset-x: -106px;
$s-btn-arr-offset-y: -124px;
$s-btn-arr-width: 11px;
$s-btn-arr-height: 11px;
$s-btn-arr-total-width: 150px;
$s-btn-arr-total-height: 149px;
$s-btn-arr-image: '../img/sprites/sprite.png';
$s-btn-arr: (106px, 124px, -106px, -124px, 11px, 11px, 150px, 149px, '../img/sprites/sprite.png', 's-btn-arr', );
$s-fc-inverse-name: 's-fc-inverse';
$s-fc-inverse-x: 98px;
$s-fc-inverse-y: 124px;
$s-fc-inverse-offset-x: -98px;
$s-fc-inverse-offset-y: -124px;
$s-fc-inverse-width: 8px;
$s-fc-inverse-height: 16px;
$s-fc-inverse-total-width: 150px;
$s-fc-inverse-total-height: 149px;
$s-fc-inverse-image: '../img/sprites/sprite.png';
$s-fc-inverse: (98px, 124px, -98px, -124px, 8px, 16px, 150px, 149px, '../img/sprites/sprite.png', 's-fc-inverse', );
$s-fc-name: 's-fc';
$s-fc-x: 88px;
$s-fc-y: 124px;
$s-fc-offset-x: -88px;
$s-fc-offset-y: -124px;
$s-fc-width: 10px;
$s-fc-height: 16px;
$s-fc-total-width: 150px;
$s-fc-total-height: 149px;
$s-fc-image: '../img/sprites/sprite.png';
$s-fc: (88px, 124px, -88px, -124px, 10px, 16px, 150px, 149px, '../img/sprites/sprite.png', 's-fc', );
$s-gallery-arr-name: 's-gallery-arr';
$s-gallery-arr-x: 0px;
$s-gallery-arr-y: 100px;
$s-gallery-arr-offset-x: 0px;
$s-gallery-arr-offset-y: -100px;
$s-gallery-arr-width: 32px;
$s-gallery-arr-height: 24px;
$s-gallery-arr-total-width: 150px;
$s-gallery-arr-total-height: 149px;
$s-gallery-arr-image: '../img/sprites/sprite.png';
$s-gallery-arr: (0px, 100px, 0px, -100px, 32px, 24px, 150px, 149px, '../img/sprites/sprite.png', 's-gallery-arr', );
$s-greek-col-1-name: 's-greek-col-1';
$s-greek-col-1-x: 50px;
$s-greek-col-1-y: 0px;
$s-greek-col-1-offset-x: -50px;
$s-greek-col-1-offset-y: 0px;
$s-greek-col-1-width: 50px;
$s-greek-col-1-height: 50px;
$s-greek-col-1-total-width: 150px;
$s-greek-col-1-total-height: 149px;
$s-greek-col-1-image: '../img/sprites/sprite.png';
$s-greek-col-1: (50px, 0px, -50px, 0px, 50px, 50px, 150px, 149px, '../img/sprites/sprite.png', 's-greek-col-1', );
$s-greek-col-2-name: 's-greek-col-2';
$s-greek-col-2-x: 0px;
$s-greek-col-2-y: 50px;
$s-greek-col-2-offset-x: 0px;
$s-greek-col-2-offset-y: -50px;
$s-greek-col-2-width: 50px;
$s-greek-col-2-height: 50px;
$s-greek-col-2-total-width: 150px;
$s-greek-col-2-total-height: 149px;
$s-greek-col-2-image: '../img/sprites/sprite.png';
$s-greek-col-2: (0px, 50px, 0px, -50px, 50px, 50px, 150px, 149px, '../img/sprites/sprite.png', 's-greek-col-2', );
$s-greek-col-3-name: 's-greek-col-3';
$s-greek-col-3-x: 50px;
$s-greek-col-3-y: 50px;
$s-greek-col-3-offset-x: -50px;
$s-greek-col-3-offset-y: -50px;
$s-greek-col-3-width: 50px;
$s-greek-col-3-height: 50px;
$s-greek-col-3-total-width: 150px;
$s-greek-col-3-total-height: 149px;
$s-greek-col-3-image: '../img/sprites/sprite.png';
$s-greek-col-3: (50px, 50px, -50px, -50px, 50px, 50px, 150px, 149px, '../img/sprites/sprite.png', 's-greek-col-3', );
$s-greek-col-4-name: 's-greek-col-4';
$s-greek-col-4-x: 100px;
$s-greek-col-4-y: 0px;
$s-greek-col-4-offset-x: -100px;
$s-greek-col-4-offset-y: 0px;
$s-greek-col-4-width: 50px;
$s-greek-col-4-height: 50px;
$s-greek-col-4-total-width: 150px;
$s-greek-col-4-total-height: 149px;
$s-greek-col-4-image: '../img/sprites/sprite.png';
$s-greek-col-4: (100px, 0px, -100px, 0px, 50px, 50px, 150px, 149px, '../img/sprites/sprite.png', 's-greek-col-4', );
$s-greek-col-5-name: 's-greek-col-5';
$s-greek-col-5-x: 0px;
$s-greek-col-5-y: 0px;
$s-greek-col-5-offset-x: 0px;
$s-greek-col-5-offset-y: 0px;
$s-greek-col-5-width: 50px;
$s-greek-col-5-height: 50px;
$s-greek-col-5-total-width: 150px;
$s-greek-col-5-total-height: 149px;
$s-greek-col-5-image: '../img/sprites/sprite.png';
$s-greek-col-5: (0px, 0px, 0px, 0px, 50px, 50px, 150px, 149px, '../img/sprites/sprite.png', 's-greek-col-5', );
$s-inst-inverse-name: 's-inst-inverse';
$s-inst-inverse-x: 115px;
$s-inst-inverse-y: 100px;
$s-inst-inverse-offset-x: -115px;
$s-inst-inverse-offset-y: -100px;
$s-inst-inverse-width: 15px;
$s-inst-inverse-height: 14px;
$s-inst-inverse-total-width: 150px;
$s-inst-inverse-total-height: 149px;
$s-inst-inverse-image: '../img/sprites/sprite.png';
$s-inst-inverse: (115px, 100px, -115px, -100px, 15px, 14px, 150px, 149px, '../img/sprites/sprite.png', 's-inst-inverse', );
$s-inst-name: 's-inst';
$s-inst-x: 100px;
$s-inst-y: 100px;
$s-inst-offset-x: -100px;
$s-inst-offset-y: -100px;
$s-inst-width: 15px;
$s-inst-height: 16px;
$s-inst-total-width: 150px;
$s-inst-total-height: 149px;
$s-inst-image: '../img/sprites/sprite.png';
$s-inst: (100px, 100px, -100px, -100px, 15px, 16px, 150px, 149px, '../img/sprites/sprite.png', 's-inst', );
$s-main-arr-name: 's-main-arr';
$s-main-arr-x: 0px;
$s-main-arr-y: 124px;
$s-main-arr-offset-x: 0px;
$s-main-arr-offset-y: -124px;
$s-main-arr-width: 25px;
$s-main-arr-height: 25px;
$s-main-arr-total-width: 150px;
$s-main-arr-total-height: 149px;
$s-main-arr-image: '../img/sprites/sprite.png';
$s-main-arr: (0px, 124px, 0px, -124px, 25px, 25px, 150px, 149px, '../img/sprites/sprite.png', 's-main-arr', );
$s-object-arr-name: 's-object-arr';
$s-object-arr-x: 100px;
$s-object-arr-y: 50px;
$s-object-arr-offset-x: -100px;
$s-object-arr-offset-y: -50px;
$s-object-arr-width: 48px;
$s-object-arr-height: 48px;
$s-object-arr-total-width: 150px;
$s-object-arr-total-height: 149px;
$s-object-arr-image: '../img/sprites/sprite.png';
$s-object-arr: (100px, 50px, -100px, -50px, 48px, 48px, 150px, 149px, '../img/sprites/sprite.png', 's-object-arr', );
$s-phone-call-name: 's-phone-call';
$s-phone-call-x: 69px;
$s-phone-call-y: 124px;
$s-phone-call-offset-x: -69px;
$s-phone-call-offset-y: -124px;
$s-phone-call-width: 19px;
$s-phone-call-height: 19px;
$s-phone-call-total-width: 150px;
$s-phone-call-total-height: 149px;
$s-phone-call-image: '../img/sprites/sprite.png';
$s-phone-call: (69px, 124px, -69px, -124px, 19px, 19px, 150px, 149px, '../img/sprites/sprite.png', 's-phone-call', );
$s-phone-primary-name: 's-phone-primary';
$s-phone-primary-x: 25px;
$s-phone-primary-y: 124px;
$s-phone-primary-offset-x: -25px;
$s-phone-primary-offset-y: -124px;
$s-phone-primary-width: 23px;
$s-phone-primary-height: 23px;
$s-phone-primary-total-width: 150px;
$s-phone-primary-total-height: 149px;
$s-phone-primary-image: '../img/sprites/sprite.png';
$s-phone-primary: (25px, 124px, -25px, -124px, 23px, 23px, 150px, 149px, '../img/sprites/sprite.png', 's-phone-primary', );
$s-phone-name: 's-phone';
$s-phone-x: 130px;
$s-phone-y: 100px;
$s-phone-offset-x: -130px;
$s-phone-offset-y: -100px;
$s-phone-width: 12px;
$s-phone-height: 12px;
$s-phone-total-width: 150px;
$s-phone-total-height: 149px;
$s-phone-image: '../img/sprites/sprite.png';
$s-phone: (130px, 100px, -130px, -100px, 12px, 12px, 150px, 149px, '../img/sprites/sprite.png', 's-phone', );
$s-select-name: 's-select';
$s-select-x: 32px;
$s-select-y: 116px;
$s-select-offset-x: -32px;
$s-select-offset-y: -116px;
$s-select-width: 11px;
$s-select-height: 6px;
$s-select-total-width: 150px;
$s-select-total-height: 149px;
$s-select-image: '../img/sprites/sprite.png';
$s-select: (32px, 116px, -32px, -116px, 11px, 6px, 150px, 149px, '../img/sprites/sprite.png', 's-select', );
$s-user-name: 's-user';
$s-user-x: 48px;
$s-user-y: 124px;
$s-user-offset-x: -48px;
$s-user-offset-y: -124px;
$s-user-width: 21px;
$s-user-height: 21px;
$s-user-total-width: 150px;
$s-user-total-height: 149px;
$s-user-image: '../img/sprites/sprite.png';
$s-user: (48px, 124px, -48px, -124px, 21px, 21px, 150px, 149px, '../img/sprites/sprite.png', 's-user', );
$spritesheet-width: 150px;
$spritesheet-height: 149px;
$spritesheet-image: '../img/sprites/sprite.png';
$spritesheet-sprites: ($s-arr-primary, $s-arr-white, $s-btn-arr, $s-fc-inverse, $s-fc, $s-gallery-arr, $s-greek-col-1, $s-greek-col-2, $s-greek-col-3, $s-greek-col-4, $s-greek-col-5, $s-inst-inverse, $s-inst, $s-main-arr, $s-object-arr, $s-phone-call, $s-phone-primary, $s-phone, $s-select, $s-user, );
$spritesheet: (150px, 149px, '../img/sprites/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
