.header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid $c-secondary;
  z-index: 999;
  &.__fixed{
    background: $c-primary-dark;
    .header{
      &-l{
        width: 40px;
        height: 40px;
      }
      &-menu{
        padding-left: 9.7%;
        &-lnk{
          padding: 30px 0;
          font-size: 16px;
        }
      }
      &-phone{
        font-size: 16px;
      }
      &-btn{
        font-size: 16px;
        padding: 10px 15px;
      }
    }
  }
  &-cnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-l{
    width: 66px;
    height: 66px;
  }
  &-lnk{
    display: block;
  }
  &-logo{
    width: 100%;
  }
  &-menu{
    padding-left: 8%;
    flex-grow: 1;
    &-lst{
      display: flex;
    }
    &-i{
      padding: 0 2%;
      &:first-of-type{
        padding-left: 0;
      }
      &:last-of-type{
        padding-right: 0;
      }
    }
    &-lnk{
      position: relative;
      display: block;
      padding: 60px 0;
      &:after{
        content: '';
        position: absolute;
        display: block;
        right: 50%;
        bottom: -1px;
        left: 50%;
        height: 2px;
        width: 0;
        background-color: $c-base;
        transition: left .4s,right .4s;
      }
      &.__active{
        &:after {
          width: 100%;
          left: 0;
          right: 0;
        }
      }
      &:hover{
        &:after{
          width: auto;
          left: 0;
          right: 0;
        }
      }
    }
    &.__show-menu{
      .header{
        &-menu{
          &-lst{
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: 320px;
            text-align: center;
            background: $c-primary-dark;
            z-index: 10;
          }
          &-lnk{
            padding: 30px 0;
          }
        }
      }
    }
  }
  &-r{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  &-phone{
    display: block;
    margin-left: 40px;
    &-mob{
      display: none;
      align-self: center;
      margin-right: 10px;
      width: 23px;
      height: 23px;
      background-repeat: no-repeat;
      @extend .s-phone-primary!optional;
      order: -1;
    }
  }
  &-btn{
    display: block;
    padding: 20px 40px;
    margin-left: 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    border-radius: 66px;
    border: 1px solid $c-secondary;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(42px);
    &:hover{
      border-color: $c-primary-light;
    }
  }
}

.menu-mob {
  display: none;
  position: absolute;
  width: 30px;
  height: 25px;
  z-index: 11;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  &-ic {
    display: block;
    cursor: pointer;
    width: 30px;
    border-top: 3px solid $c-base;
    border-bottom: 3px solid $c-base;
    padding: 7px 0;
    line-height: 1;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 100%;
      background: $c-base;
    }
    &:before{
      display: none;
    }
    &.__active{
      border: none;
      height: 30px;
      &:after{
        position: absolute;
        top: 0;
        right: 15px;
        width: 2px;
        height: 25px;
        background-color: $c-base;
        transform: rotate(-45deg);
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
        width: 2px;
        height: 25px;
        background-color: $c-base;
        transform: rotate(45deg);
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .header{
    &-phone{
      margin-left: 15px;
    }
    &-btn{
      margin-left: 20px;
      padding: 20px;
    }
  }
}
@media screen and (max-width: 1300px) {
  .header{
    &-phone{
      margin-left: 15px;
      font-size: 16px;
    }
    &-btn{
      margin-left: 15px;
      padding: 15px 20px;
      font-size: 16px;
    }
    &-menu{
      &-lnk{
        font-size: 16px;
      }
    }
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 14px;
  }
}
@media screen and (max-width: 1100px) {
  .header{
    &-phone{
      display: none;
      &-mob{
        display: block;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .menu-mob{
    display: block;
  }
  .header{
    &-l{
      width: 40px;
      height: 40px;
    }
    &-cnt{
      position: relative;
      padding: 20px 0
    }
    &-r{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 65px;
      height: 35px;
      margin-right: 60px;
    }
    &-menu{
      padding-left: 0;
      flex-grow: unset;
      order: 1;
      &-lst{
        display: none;
      }
    }
    &-btn{
      display: none;
    }
  }
}