.questions{
  &-row{
    @extend .row;
    margin-right: -15px;
    margin-left: -15px;
  }
  &-col{
    @include make-col-ready();
    @include make-col(6);
    padding-right: 15px;
    padding-left: 15px;
  }
  &-info{
    margin-top: 60px;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.5;
  }
  &-phone{
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 60px;
    padding-left: 50px;
    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 30px;
      width: 65px;
      height: 65px;
      margin: auto 0;
      border-radius: 50%;
      border-top: 1px solid $c-primary;
      transform: rotate(-140deg);
      opacity: .2;
    }
    &:after{
      left: 0;
      width: 100px;
      height: 100px;
      transform: rotate(-120deg);

    }

    &-img{
      position: relative;
      width: 31px;
      height: 31px;
      background: $c-primary;
      border-radius: 50%;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 12px;
        height: 12px;
        @extend .s-phone;
      }
    }
    &-number{
      display: block;
      font-weight: 500;
      font-size: 33px;
      margin-left: 20px;
    }
  }
  &-i{
    border-top: 1px solid rgba($c-border, .13);
    &:last-of-type{
      border-bottom: 1px solid rgba($c-border, .13);
    }
    &-lnk{
      display: block;
      position: relative;
      font-family: $ttl-font;
      font-size: 26px;
      font-weight: 400;
      line-height: 1.4;
      text-transform: uppercase;
      padding: 30px 0 30px 50px;
      &:before{
        content: '+';
        display: block;
        position: absolute;
        top: 22px;
        bottom: 0;
        left: 0;
        font-size: 33px;
        width: auto;
        height: 45px;
      }
      &:hover{
        color: $c-primary;
      }
      &.__active{
        color: $c-primary;
        &:before{
          transform: rotate(-45deg);
        }
      }
    }
    &-txt{
      font-size: 18px;
      font-weight: 400;
      line-height: 1.6;
      padding: 0 0 30px 50px;
    }
  }
}
@media screen and (max-width: 1024px){
  .questions {
    &-row {
      margin-left: 0;
      margin-right: 0;
    }
    &-col {
      @include make-col(12);
      padding-left: 0;
      padding-right: 0;
      &:first-of-type{
        margin-bottom: 80px;
      }
    }
  }
}
@media screen and (max-width: 800px){
  .questions {
    &-info{
      margin-top: 40px;
      font-size: 16px;
    }
    &-phone{
      padding-left: 20px;
      &:before{
        left: 12px;
        width: 45px;
        height: 45px;
      }
      &:after{
        width: 70px;
        height: 70px;
      }
      &-number{
        font-size: 23px;
      }
    }
    &-i{
      &-lnk{
        font-size: 20px;
      }
      &-txt{
        font-size: 14px;
      }
    }
  }
}