.advantage{
  .ttl-transform{
    margin-top: 100px;
    margin-left: -35px;
  }
  &-row{
    @extend .row;
    margin-right: -10px;
    margin-left: -10px;
    margin-top: 70px;
  }
  &-col {
    @extend .col;
    padding: 20px 10px;
  }
  &-card{
    height: 100%;
    border: 1px solid $c-secondary;
    border-radius: 16px;
    background: linear-gradient(152.97deg, rgba(30, 34, 38, 0.66) 0%, rgba(30, 34, 38, 0) 100%);
    &-t{
      height: 240px;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    &-b{
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0 30px 30px 12px;
    }
    &-badge{
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 8px 18px;
      background: linear-gradient(152.97deg, rgba(30, 34, 38, 0.66) 0%, rgba(30, 34, 38, 0.33) 100%);
      backdrop-filter: blur(42px);
      border-radius: 3px;
      margin-bottom: 5px;
      line-height: 1.3;
    }
    &-cnt{
      padding: 30px;
    }
    &-ttl{
      font-weight: 600;
      font-size: 21px;
      line-height: 1.4;
      padding-bottom: 20px;
    }
    &-txt{
      font-size: 16px;
      line-height: 1.5;
    }
  }
}
@media screen and (max-width: 1200px){
  .advantage{
    &-col{
      @include make-col(6);
    }
  }
}

@media screen and (max-width: 900px){
  .advantage{
    &-row{
      margin-right: 0;
      margin-left: 0;
      margin-top: 45px;
    }
    &-col{
      @include make-col(12);
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media screen and (max-width: 800px) {
  .advantage{
    &-card{
      &-cnt{
        padding: 25px;
      }
      &-ttl{
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .advantage{
    &-card{
      &-b{
        margin: 0 30px 15px 12px;
      }
      &-bage{
        font-size: 14px;
      }
      &-ttl{
        font-size: 14px;
      }
      &-txt{
        font-size: 13px;
      }
    }
  }
}