.consultation{
  &-txt{
    position: relative;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.5;
    margin-top: 30px;
    max-width: 730px;
    padding-left: 100px;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 70px;
      background: $c-base;
      margin-top: 15px;
    }
  }
}
@media screen and (max-width: 800px){
  .consultation{
    &-txt{
      font-size: 14px;
      max-width: 500px;
      padding-left: 60px;
      &:before{
        width: 30px;
      }
    }
  }
}