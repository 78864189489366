.why{
  &-wrap{
    position: relative;
    padding-bottom: 500px;
    &:before{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 50px;
      background: url("../img/object-logo.png") no-repeat;
      background-size: cover;
      max-width: 500px;
      width: 100%;
      height: 500px;
      margin-left: 4%;
      z-index: 1;
    }
  }
  &-t{
    //margin-right: 49%;
    margin-bottom: -23%;
    max-width: 66%;
  }
  &-row{
    @extend .row;
    margin-right: -25px;
    margin-left: -25px;
  }
  &-col{
    @extend .col;
    padding: 25px;
    &:nth-of-type(1){
      align-self: center;
      .why-card-ic:after{
        @extend .s-greek-col-1;
      }
    }
    &:nth-of-type(2){
      align-self: flex-end;
      .why-col-cnt{
        &:nth-of-type(1){
          .why-card-ic:after{
            @extend .s-greek-col-2;
          }
        }
        &:nth-of-type(2){
          .why-card-ic:after{
            @extend .s-greek-col-4;
          }
        }
      }
    }
    &:nth-of-type(3){
      align-self: flex-start;
      .why-col-cnt{
        &:nth-of-type(1){
          .why-card-ic:after{
            @extend .s-greek-col-3;
          }
        }
        &:nth-of-type(2){
          .why-card-ic:after{
            @extend .s-greek-col-5;
          }
        }
      }
    }
    &:nth-of-type(4){
      margin-left: 33.333333%;
      .why-card-ic:after{
        @extend .s-greek-col-4;
      }
    }
    &:nth-of-type(5){
      .why-card-ic:after{
        @extend .s-greek-col-5;
      }
    }
    &-cnt{
      &:nth-of-type(2){
        padding-top: 50px;
      }
      &:nth-of-type(3){

      }
    }
    &-wrap{
      padding-top: 70%;
    }
    &-cnt{
      &.__pt-card{
        padding-top: 30%;
      }
    }
  }
  &-card{
    background: $c-txt;
    padding: 100px 50px 50px;
    font-size: 17px;
    line-height: 1.4;
    &-ic{
      position: relative;
      width: 120px;
      height: 120px;
      background: $c-primary;
      border-radius: 50%;
      margin-bottom: 30px;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 50px;
        height: 50px;
        z-index: 1;
      }
    }
    &-txt{
      font-weight: 400;
    }
    &-ttl{
      font-weight: 600;
    }
  }
  &-b{
    position: relative;
    margin: 150px 0 0;
    &-cnt{
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      font-family: $ttl-font;
    }
    &-ttl{
      font-size: 170px;
    }
    &-txt{
      font-size: 38px;
      line-height: 1.4;
      margin-left: 2%;
      max-width: 245px;
    }
  }
  &-btn{
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    bottom: -120px;
    margin: 0 auto;
    width: 200px;
    height: 200px;
    border: 1px solid $c-primary-light;
    border-radius: 50%;
    z-index: 2;
    &:after{
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 70px;
      left: 0;
      width: 47px;
      height: 47px;
      margin: 0 auto;
      @extend .s-object-arr;
      z-index: 3;
    }
    &:hover{
      background: $c-base;
    }
  }
}
@media screen and (max-width: 1300px){
  .why{
    &-wrap{
      &:before{
        max-width: 400px;
        height: 400px;
      }
    }
    &-card{
      padding: 50px 35px 50px;
    }
    &-b{
      &-cnt{
        flex-wrap: wrap;
      }
      &-ttl{
        width: 100%;
        padding-bottom: 15px;
      }
      &-txt{
        line-height: 1.4;
        margin-left: 0;
        width: 100%;
      }
    }
    &-btn{
      width: 130px;
      height: 130px;
      bottom: -100px;
      &:after{
        width: 27px;
        height: 27px;
        background: url("../img/object-arr-s.png") no-repeat;
        bottom: 0;
        top: 0;
        margin: auto;
      }
    }
  }
}
@media screen and (max-width: 1024px){
  .why{
    &-wrap{
      padding-bottom: 300px;
      &:before{
        margin-left: 0;
      }
    }
    &-row{
      margin-right: 0;
      margin-left: 0;
    }
    &-col{
      @include make-col(12);
      padding-right: 0;
      padding-left: 0;
      &-wrap{
        padding-top: 0;
      }
    }
    &-b{
      &-cnt{
        top: -25px;
      }
      &-ttl{
        font-size: 120px;
      }
      &-txt{
        font-size: 34px;
      }
    }
  }
}
@media screen and (max-width: 800px){
  .why{
    &-card{
      padding: 35px;
    }
    &-col{
      padding-top: 0;
      padding-bottom: 25px;
    }
    &-card{
      font-size: 14px;
      &-ic{
        width: 95px;
        height: 95px;
      }
    }

  }
}
@media screen and (max-width: 680px){
  .why{
    &-t{
      max-width: 100%;
    }
    &-wrap{
      &:before{
        max-width: 210px;
        height: 210px;
        left: -20px;
        bottom: 155px;
      }
    }
    &-b{
      &-cnt{
        top: 15px;
      }
      &-ttl{
        font-size: 78px;
      }
      &-txt{
        max-width: 200px;
        font-size: 18px;
      }
    }
  }
}