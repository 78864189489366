.ttl{
  font-family: $ttl-font;
  font-size: 62px;
  line-height: 1.4;
  text-transform: uppercase;
}
.sub-ttl{
  font-weight: 600;
  font-size: 19px;
  text-transform: uppercase;
  color: $c-primary;
  margin-bottom: 20px;
}
.ttl-transform{
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  margin-top: 62px;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  z-index: 1;
  &-nbr,
  &-ltr{
    color: $c-primary-light;
  }
  &-ltr{
    padding-left: 15px;
    letter-spacing: -4px;
  }
  &-txt{
    color: $c-base;
  }
}
@media screen and (max-width: 1200px){
  .ttl{
    font-size: 42px;
  }
}
@media screen and (max-width: 800px){
  .ttl-transform{
    display: none;
  }
  .ttl{
    font-size: 30px;
  }
  .sub-ttl{
    font-size: 12px;
  }
}