.footer{
  background: $c-primary-dark;
  &-cnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 100px;
    border-bottom: 1px solid #212A56;
  }
  &-l{
    width: 147px;
    height: auto;
  }
  &-lnk{
    display: block;
  }
  &-logo{
    width: 100%;
  }
  &-menu{
    flex-grow: 1;
    padding: 0 10px;
    &-lst{
      display: flex;
      justify-content: center;
    }
    &-i{
      padding: 0 3%;
      &:last-of-type{
        padding-right: 0;
      }
    }
    &-lnk{
      display: block;
      font-size: 18px;
      font-weight: 500;
      color: $c-secondary;
      &:hover{
        color: $c-base;
      }
    }
  }
  &-btn{
    display: block;
    position: relative;
    max-width: 345px;
    width: 100%;
    padding: 30px 50px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: $c-base;
    border: 1px solid $c-primary;
    border-radius: 66px;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto 30px auto;
      width: 11px;
      height: 11px;
      @extend .s-btn-arr;
    }
    &:hover{
      border-color: $c-base;
    }
  }
  &-b{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 45px;
    color: $c-secondary;
    font-weight: 500;
    font-size: 18px;
    &-cop{
      text-transform: uppercase;
      padding-right: 15px;
    }
    &-lnk{
      text-decoration: underline;
      &:hover{
        color: $c-base;
        text-decoration: none;
      }
    }
  }
  &-soc{
    display: flex;
    align-items: center;
    margin-left: 15px;
    &.__mob{
      display: none;
    }
    &-txt{
      display: none;
    }
    &-btn{
      position: relative;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid $c-base;
      background: $c-base;
      width: 60px;
      height: 60px;
      line-height: 60px;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 16px;
        margin: auto;
      }
      &:first-of-type {
        margin-right: 15px;
      }
      &.__inst{
        &:after{
          width: 15px;
          @extend .s-inst;
        }
      }
      &.__fc{
        &:after{
          width: 10px;
          @extend .s-fc;
        }
      }
      &:hover{
        border-color: $c-primary-light;
        background: $c-primary-light;
        &.__inst{
          &:after{
            @extend .s-inst-inverse;
          }
        }
        &.__fc{
          &:after{
            @extend .s-fc-inverse;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px){
  .footer{
    &-l{
      width: 100px;
    }
    &-btn{
      flex-shrink: 0;
      padding: 30px 40px 30px 20px;
      &:after{
        margin: auto 20px auto;
      }
    }
  }
}
@media screen and (max-width: 1150px) {
  .footer{
    &-menu{
      &-lst{
        flex-wrap: wrap;
      }
      &-i{
        margin-top: 15px;
      }
    }
    &-r{
      flex-shrink: 0;
    }
    &-cnt{
      padding-bottom: 50px;
    }
  }
}
@media screen and (max-width: 900px) {
  .footer{
    &-cnt{
      flex-wrap: wrap;
    }
    &-l{
      width: 147px;
      margin: 0 auto;
    }
    &-menu{
      width: 100%;
      margin: 30px 0 50px;
      &-i{
        width: 100%;
        padding: 0;
        margin-top: 30px;
        text-align: center;
      }
      &-lnk{
        color: $c-base;
      }
    }
    &-r{
      margin: 0 auto;
    }
    &-btn{
      font-size: 14px;
      padding: 30px 50px;
    }
    &-soc{
      display: none;
      &.__mob{
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
      }
      &-txt{
        display: block;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: $c-secondary;
        margin: 50px 0 20px;
        width: 100%;
      }
    }
    &-b{
      flex-wrap: wrap;
      justify-content: center;
      font-size: 14px;
      &-lnk{
        margin-bottom: 15px;
        order: -1;
      }
      &-cop{
        width: 100%;
        text-align: center;
      }
    }
  }
}