.mfp-bg {
  z-index: 99999;
}
.mfp-wrap {
  z-index: 99999;
}
.popup-box{
  float: none;
  padding: 50px 50px 30px;
}
.popup {
  max-width: 512px;
  margin: 0 auto;
  background-color: $c-base;
  position: relative;
  border-radius: 10px;
  &-form{
    max-width: 335px;
    width: 100%;
    margin:  0 auto;
  }
  .inp-order-lbl{
    position: relative;
    display: block;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 30px;
      bottom: 0;
      margin: auto 0;
      width: 19px;
      height: 19px;
      @extend .s-phone-call;
    }
  }
  .wrap-inp{
    &:first-of-type{
      .inp-order-lbl{
        &:after{
          width: 21px;
          height: 21px;
          @extend .s-user;
        }
      }
    }
  }
  .contacts-btn{
    font-size: 14px;
    padding: 27px 60px 27px 35px;
    border-radius: 1000px;
  }
  &-inp{
    font-size: 14px;
    padding: 35px 60px 35px 35px;
    border: 1px solid #EFF1F5;
    border-radius: 1000px;
    &::-webkit-input-placeholder {
      font-size: 14px;
    }
    &::-moz-placeholder{
      font-size: 14px;
    }/* Firefox 19+ */
    &:-moz-placeholder{
      font-size: 14px;
    }/* Firefox 18- */
    &:-ms-input-placeholder{
      font-size: 14px;
    }
  }
  &-btn{
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    &:before,
    &:after{
      position: absolute;
      left: 8px;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: $c-desc;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
  &-ttl{
    font-family: $ttl-font;
    font-size: 30px;
    font-weight: 400;
    color: $c-primary-dark;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 680px) {
  .popup{
    &-box{
      padding: 50px 15px 20px;
    }
    &-ttl{
      font-size: 21px;
    }
    &-btn{
      right: 15px;
      top: 15px;
    }
    .contacts-btn {
      font-size: 12px;
      padding: 27px 50px 27px 35px;
    }
  }
}